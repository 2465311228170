<template>
    <div class="my-table">
        <tag-list v-model="type" :list='tagList' />

        <el-table ref="el-table" :data="selected" style="width: 100%" stripe size="mini" max-height="500" :cell-style="cellStyle">
            <el-table-column prop="staffName" label="员工" min-width="60px"></el-table-column>

            <template v-for="(item,index) in tableList">
                <el-table-column :key="index" :prop="item.prop" :label="item.label" :min-width="item.width" sortable :formatter="formatter">
                </el-table-column>
            </template>
        </el-table>
    </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import 'element-ui/lib/theme-chalk/table.css'
import 'element-ui/lib/theme-chalk/table-column.css'

import TagList from './TagList'
import { accMul } from '@/utils/index'
export default {
    components: { TagList, [Table.name]: Table, [TableColumn.name]: TableColumn },
    props: {
        selected: Array
    },
    data() {
        return {
            type: '1',
            tagList: [
                { name: '1', label: '首次跟进时长' },
                { name: '2', label: '12小时内未跟进' },
                { name: '3', label: '未按照返款规则拨打' }
            ],
            tableList: []
        }
    },
    watch: {
        type: {
            handler(e) {
                if (e == '1') {
                    this.tableList = [
                        { prop: 'firstFollowMinus', label: '用时(小时)', width: '90px' },
                        { prop: 'comparedFirstFollowMinus', label: '上周期用时', width: '90px' },
                        { prop: 'firstFollowMinusChangePercent', label: '环比', width: '60px', slot: true }
                    ]
                } else if (e == '2') {
                    this.tableList = [
                        { prop: 'unfollowClueNumIn12Hour', label: '数量(个)', width: '90px' },
                        { prop: 'unfollowClueInTotalPercent', label: '占总比', width: '90px' },
                        { prop: 'unfollowClueChangePercent', label: '环比', width: '60px', slot: true }
                    ]
                } else if (e == '3') {
                    this.tableList = [
                        { prop: 'illegalClue', label: '数量(个)', width: '90px' },
                        { prop: 'illegalClueInTotalPercent', label: '占总比', width: '90px' },
                        { prop: 'illegalClueChangePercent', label: '环比', width: '60px', slot: true }
                    ]
                }
                this.$refs['el-table']?.clearSort()
            },
            immediate: true
        }
    },
    methods: {
        formatter(row, column, cellValue, index) {
            if (column.property.includes('Percent')) {
                if (!cellValue && (cellValue != 0)) return "--"
                return accMul(cellValue, 100) + '%'
            } else {
                return cellValue
            }
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 3) {
                if (row[column.property] > 0) return 'color: red !important'
                else return 'color: green !important'
            } else return ''
        }
    }
};
</script>

<style scoped lang="less">
.my-table {
    /deep/.el-table {
        margin-top: 10px;
        background-color: var(--cardBackgroundColor);
        &::before {
            height: 0;
        }
        tr .el-table__cell {
            color: var(--subTitleColor);
            & > .cell {
                padding: 0;
                text-align: center;
                font-size: 12px;
            }
        }
        .el-table__cell {
            background-color: var(--cardBackgroundColor);
            border: none;
        }
        .el-table__row--striped .el-table__cell {
            background-color: var(--secondCardBackgroundColor);
        }
    }
}
</style>

<template>
    <div class="statistic">
        <my-selected
            v-model="selected"
            :list="userList"
            @change="selectChange"
        />

        <card class="data-card">
            <template #title>
                <span style="margin-right: 5px; font-weight: 600">
                    关键数据
                </span>
                <!-- <van-icon name="question-o" /> -->
            </template>

            <template>
                <div class="flex-box">
                    <span class="font-sm">首次跟进时长</span>
                    <span>
                        {{ baseData.firstFollowMinus }}
                        <span class="font-sm">小时</span>
                    </span>
                </div>
                <div class="flex-box">
                    <span class="font-sm">12小时内未跟进</span>
                    <span>
                        {{ baseData.unfollowClueNumIn12Hour }}
                        <span class="font-sm">个</span>
                    </span>
                </div>
                <div class="flex-box">
                    <span class="font-sm">未按照返款规则拨打</span>
                    <span>
                        {{ baseData.illegalClue }}
                        <span class="font-sm">个</span>
                    </span>
                </div>
            </template>
        </card>

        <card title="工作量统计">
            <my-carts :selected="selected"></my-carts>
        </card>

        <card title="明细数据">
            <my-table :selected="selected" />
        </card>
    </div>
</template>

<script>
import MySelected from "./component/MySelected.vue"
import { accAdd, accDiv } from "@/utils/index"
import Card from "./component/Card"
import MyCarts from "./component/MyCarts"
import MyTable from "./component/MyTable"

import { queryReport } from "@/api/feiyu/index"
export default {
    components: { MySelected, Card, MyCarts, MyTable },
    data() {
        return {
            userList: [], // 所有人
            selected: [], // 已选的
            baseData: {}
        }
    },
    created() {
        this.getDetail()
    },
    methods: {
        async getDetail() {
            this.$toast.loading({ duration: 0 })
            const { value } = await queryReport(this.$route.query.reportId)
            value.forEach((item) => {
                item.firstFollowMinus = item.firstFollowMinus
                    ? (item.firstFollowMinus / 60).toFixed(1) * 1
                    : 0
                item.comparedFirstFollowMinus = item.comparedFirstFollowMinus
                    ? (item.comparedFirstFollowMinus / 60).toFixed(1) * 1
                    : 0
            })
            this.userList = value
            this.selected = value
            this.selectChange()
            this.$toast.clear()
        },

        selectChange(list = this.selected) {
            const { firstFollowMinus, unfollowClueNumIn12Hour, illegalClue } =
                list.reduce(
                    (
                        {
                            firstFollowMinus,
                            unfollowClueNumIn12Hour,
                            illegalClue
                        },
                        item
                    ) => {
                        return {
                            firstFollowMinus: accAdd(
                                firstFollowMinus,
                                item.firstFollowMinus
                            ),
                            unfollowClueNumIn12Hour: accAdd(
                                unfollowClueNumIn12Hour,
                                item.unfollowClueNumIn12Hour
                            ),
                            illegalClue: accAdd(illegalClue, item.illegalClue)
                        }
                    }
                )
            this.baseData = {
                unfollowClueNumIn12Hour,
                illegalClue,
                firstFollowMinus: accDiv(firstFollowMinus, list.length).toFixed(
                    1
                )
            }
        }
    }
}
</script>

<style scoped lang="less">
.my-selected {
    margin-bottom: 12px;
}
.data-card {
    /deep/.card-content {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        span:first-child {
            margin-bottom: 8px;
        }
    }
    .flex-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.font-sm {
    font-size: 12px;
    color: var(--subTitleColor);
}

.my-selected {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    z-index: 100;
}
</style>

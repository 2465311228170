<template>
    <div :class="['card', {'inset': inset}]">
        <div class="card-header">
            <slot v-if="$slots.header" name="header" />
            <template v-else>
                <span class="card-header__title">
                    <slot v-if="$slots.title" name="title" />
                    <template v-else>{{ title }}</template>
                </span>
                <span v-if="value" class="card-header__value">{{ value }}</span>
            </template>
        </div>

        <div v-if="$slots.default || content" class="card-content">
            <slot v-if="$slots.default" />
            <template v-else>
                {{ content }}
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        content: String,
        value: String,
        inset: {
            type: Boolean,
            default: true
        }
    },
};
</script>

<style scoped lang="less">
.card {
    font-size: 14px;
    padding: 12px;
    background-color: var(--cardBackgroundColor);
    &.inset {
        margin: 12px 16px;
        border-radius: @border-radius;
    }
    &-header {
        display: flex;
        justify-content: space-between;
        &__title {
            font-weight: 600;
        }
        &__value {
            color: var(--mainColor);
            font-size: 12px;
        }
    }
    &-content {
        margin-top: 8px;
    }
}
</style>

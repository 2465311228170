<template>
    <div class="my-chart">
        <tag-list v-model="type" :list='tagList' @input="initData($event, buttonType);" />
        <div ref="chart" class="chart">
        </div>
        <span v-if="dataArray.length > 5" style="color: var(--mainColor); font-size: 12px" @click="operate">
            {{ buttonName }}
        </span>
    </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import { GridComponent, MarkLineComponent } from 'echarts/components';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
    GridComponent,
    MarkLineComponent,
    BarChart,
    CanvasRenderer
]);

import TagList from './TagList'
export default {
    components: { TagList },
    props: {
        selected: Array
    },
    data() {
        return {
            dataArray: [],
            type: '1',

            buttonName: "展开",
            buttonType: "default",

            tagList: [{
                name: '1',
                label: '首次跟进时长'
            }, {
                name: '2',
                label: '12小时内未跟进'
            }, {
                name: '3',
                label: '未按照返款规则拨打'
            }]
        }
    },
    watch: {
        selected: {
            handler(val) {
                if (!val) return
                this.dataArray = JSON.parse(JSON.stringify(val));
                this.initData(this.type, this.buttonType);
            }
        }
    },
    methods: {
        operate() {
            if (this.buttonType == 'all') {
                this.buttonType = "defalut";
                this.buttonName = "展开";
            } else {
                this.buttonType = "all";
                this.buttonName = "收起";
            }
            this.initData(this.type, this.buttonType)
        },

        initData(type, numType) {
            const [keyName, keyName2] = {
                1: ['firstFollowMinus', 'firstFollowMinusChangePercent'],
                2: ['unfollowClueNumIn12Hour', 'unfollowClueChangePercent'],
                3: ['illegalClue', 'illegalClueChangePercent']
            }[type]
            let showMax = numType == 'all' ? this.dataArray.length : this.dataArray.length > 5 ? 5 : this.dataArray.length,
                initXdata = [],
                initYdata = [];

            this.dataArray.sort((a, b) => b[keyName] - a[keyName])

            //封装chart需要的数据格
            this.dataArray.forEach(item => {
                initXdata.push({
                    name: item.staffName,
                    value: item[keyName] * 1,
                    value2: item[keyName2] * 1,
                });
                initYdata.push(item.staffName);
            });
            this.setOption({ initXdata, initYdata, unitName: keyName == 'firstFollowMinus' ? '小时' : '个', showMax })
        },

        setOption({ initXdata, initYdata, unitName, showMax }) {
            const option = {
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: 35,
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    show: false,
                },
                yAxis: {
                    type: 'category',
                    inverse: true,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        inside: true,
                        formatter: val => {
                            return `{name|${val}}`
                        },
                        rich: {
                            name: {
                                padding: [0, 0, 40, -7]
                            }
                        }
                    },
                    data: initYdata,
                    max: showMax - 1
                },
                series: [{
                    type: 'bar',
                    data: initXdata,
                    barWidth: '30%',
                    markLine: {
                        silent: true,
                        symbol: ['none', 'none'],
                        label: {
                            show: true,
                            position: 'start',
                            formatter: ele => {
                                return `                 平均值:${ele.value}${unitName}`
                            },
                            textStyle: {
                                color: '#888'
                            }
                        },
                        data: [{
                            type: 'average'
                        }]
                    },
                    label: {
                        show: true,
                        position: 'right',
                        valueAnimation: true,
                        formatter: val => {
                            const className = val.data.value2 > 0 ? 'name2' : 'name3'
                            return `{name|${val.data.value}${unitName}}\n{${className}|${(val.data.value2 * 100).toFixed(0) + "%"}}`;
                        },
                        rich: {
                            name: {
                                fontSize: 12,
                                padding: [0, 0, 15, 0],
                                color: "#888",
                                align: 'center'
                            },
                            name2: {
                                color: "#e80000",
                                fontSize: 11,
                                padding: [-15, 0, 0, 0],
                                align: 'center'
                            },
                            name3: {
                                color: "green",
                                fontSize: 11,
                                padding: [-15, 0, 0, 0],
                                align: 'center'
                            }
                        }
                    }
                }]
            }
            if (this.$chart) {
                this.$chart.resize({ height: showMax * 50 + 60 });
            } else {
                this.$chart = echarts.init(this.$refs.chart, null, { height: showMax * 50 + 60 });
            }
            this.$chart.setOption(option)

        }
    }
};
</script>

<style scoped lang="less">
.my-chart {
    .van-tag {
        font-size: 10px;
        margin-right: 10px;
        padding: 4px 8px;
    }
}

.chart {
    margin-top: 10px;
    width: 100%;
}
</style>
<template>
    <div class="my-selected">
        <van-dropdown-menu active-color="var(--mainColor)">
            <van-dropdown-item ref="dropdown" title="所有员工" @open="onOpen">
                <div class="user-box">
                    <!-- 搜索框 -->
                    <van-search v-model="input" placeholder="请输入姓名" class="user-box-header van-hairline--bottom" @input="inputChange" />
                    <div class="user-box_content">
                        <!-- 侧边栏 -->
                        <van-sidebar v-model="activeKey">
                            <van-sidebar-item v-for="(item,index) in area" :key="index" :title="item.name" @click="sidebarChange(index)" />
                        </van-sidebar>

                        <!-- 用户列表 -->
                        <div class="user-list">
                            <van-checkbox v-model="checkAll" @click="clickCheckAll">全选</van-checkbox>

                            <van-checkbox v-for="(item,index) in userList" v-model="item.selected" :key="index" @change="isCheckAll">
                                {{ item.staffName }}
                            </van-checkbox>
                        </div>
                    </div>
                    <div class="user-box_footer">
                        <van-button square @click='clear'>清空</van-button>
                        <van-button color="var(--mainColor)" square @click="update">确定</van-button>
                    </div>
                </div>
            </van-dropdown-item>
        </van-dropdown-menu>
    </div>
</template>

<script>
import { debounce } from '@/utils/index'
export default {
    props: {
        list: Array,
        value: Array
    },
    data() {
        return {
            activeKey: 0,
            input: '',
            checkAll: false,

            area: [], // 侧边栏
            userList: [], // 展示的用户列表
            allUsers: [] // 全部用户添加选中状态
        };
    },
    methods: {
        // 点击全选
        clickCheckAll() {
            this.userList.forEach(item => item.selected = this.checkAll)
        },

        // 侧边栏变化
        sidebarChange(activeKey) {
            this.input = ''
            this.getUserList({ activeKey })
            this.isCheckAll()
        },

        // 获取显示的用户列表 判断当前是否全选状态
        getUserList({ activeKey, searchVal }) {
            if (activeKey || activeKey == 0) {
                this.userList = this.area[activeKey]?.list
            } else {
                this.userList = this.allUsers.filter(item => item.staffName.includes(searchVal))
            }
        },

        // 判断当前是否全选状态
        isCheckAll() {
            this.checkAll = !this.userList.find(item => !item.selected)
        },

        // 打开弹窗
        onOpen() {
            this.input = ''
            this.activeKey = 0
            const userList = JSON.parse(JSON.stringify(this.list))
            const area = [{ name: '默认分组', list: userList }]
            const selectedList = this.value.map(item => item.staffName)

            userList.forEach(item => {
                item.selected = selectedList.includes(item.staffName) // 设置选中状态
                const index = area.findIndex(val => val.name == item.districtName) // 设置所有大区
                if (index >= 0) {
                    area[index].list.push(item)
                } else {
                    area.push({
                        name: item.districtName,
                        list: [item]
                    })
                }
            })
            this.area = area
            this.allUsers = userList

            this.getUserList({ activeKey: 0 })
            this.isCheckAll()
        },

        // 点击确认提交选中成员
        update() {
            const list = this.allUsers.filter(item => item.selected)
            if (!list.length) return this.$toast('请选择最少一个用户')
            this.$emit('input', list)
            this.$emit('change', list)
            this.$refs.dropdown.toggle(false)
        },

        // 情空
        clear() {
            this.allUsers.forEach(item => item.selected = false)
        },

        // 搜索名字
        inputChange: debounce(function () {
            this.activeKey = 0
            this.getUserList({ searchVal: this.input })
            this.isCheckAll()
        })
    },
};
</script>

<style scoped lang="less">
.my-selected {
    // .van-search {
    //     position: sticky;
    //     top: 0;
    // }
    /deep/.van-dropdown-item__content {
        height: 80%;
        border-radius: 0 0 10px 10px;
    }
    ::-webkit-scrollbar {
        display: none; /*隐藏滚轮*/
    }
    .user-box {
        height: 100%;
        &_content {
            height: calc(100% - 98px);
            overflow-y: auto;
            display: flex;
            .van-dropdown-menu {
                /deep/.van-dropdown-menu__title .van-ellipsis {
                    font-size: 13px;
                }
            }

            .van-sidebar {
                width: 120px;
                .van-sidebar-item {
                    padding: 15px 12px;
                    /deep/.van-sidebar-item__text {
                        font-size: 12px;
                    }

                    &--select /deep/.van-sidebar-item__text {
                        font-weight: 600;
                    }
                }
                .van-sidebar-item--select::before {
                    display: none;
                }
            }

            .user-list {
                flex: 1;
                padding: 15px 12px 0 12px;
                overflow-y: auto;

                .van-checkbox {
                    margin-bottom: 22px;
                }
            }
        }

        &_footer {
            .van-button {
                width: 50%;
            }
        }
    }
}
</style>

<template>
    <div class="tag-list">
        <van-tag v-for="(item, index) in list" :key="index" :class="{active: item.name == value}" size="medium"
            @click="$emit('input', item.name)">
            {{ item.label }}
        </van-tag>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            require: true
        },
        list: Array
    },
};
</script>

<style scoped lang="less">
.van-tag {
    font-size: 10px;
    margin-right: 10px;
    padding: 4px 8px;
    background-color: var(--background);
    color: var(--subTitleColor);

    &.active {
        background-color: var(--mainColor);
        color: #fff;
    }
}
</style>
